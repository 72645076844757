// http://ionicframework.com/docs/theming/
@import '~@ionic/angular/css/core.css';
@import '~@ionic/angular/css/normalize.css';
@import '~@ionic/angular/css/structure.css';
@import '~@ionic/angular/css/typography.css';
@import '~@ionic/angular/css/display.css';
@import '~@ionic/angular/css/padding.css';
@import '~@ionic/angular/css/float-elements.css';
@import '~@ionic/angular/css/text-alignment.css';
@import '~@ionic/angular/css/text-transformation.css';
@import '~@ionic/angular/css/flex-utils.css';


// common
.item-sub-title,  .item-title {
    display: block;
}

.item-title {
    color: white;
    font-size: 19px;
    font-weight: bold;
    margin-bottom: 25px;

    .action {
        float: right;
        color: #93999f;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 700;
    }
}

:host(.ion-color) .inner-scroll {
    background: current-color(base);
    color: current-color(contrast);
}


.text-red {
    color: #ec001e;
}

.text-curator {
    color: #FFBF30;
}

.text-label {
    color: #42C8B5;
}

.text-blue {
    color: #14e8ff;
}

.text-fire {
    color: #ffa500;
}

.text-muted, .muted {
    color: #515258;
}

.album-actionsheet-modal {
    padding: 35vh 0px 0px;
    background: rgba(0, 0, 0, 0.65);
    .modal-wrapper {
        background: transparent !important
    }
}

.alert-radio-label {
    color: #ffffff !important;
}

// FORMS
ion-label {
    font-size: 0.75rem;
    margin-top: 3px;
    margin-bottom: 4px;
    display: block;
}

ion-input,
ion-select,
ion-textarea,
ion-select.dropdown {
    --background: #1B1B1C;
    --padding-start: 15px;
    --padding-end: 15px;
    --padding-top: 15px;
    --padding-bottom: 15px;
    border-radius: 8px;
}

ion-action-sheet {
    --color: #ffffff;
}

ion-tab-bar {
    --background: #141415;
}


ion-alert {
    --background: #ffffff !important;

    .alert-sub-title, .alert-title {
        color: #000000 !important;
    }

    .alert-message {
        color: #1B1B1C !important;
    }
    
    .alert-input {
        color: #1B1B1C !important;
        background: #ffffff !important;
    }
}

ion-modal {
    --background: #080808 !important;
}

ion-backdrop {
    opacity: 0.9;
}
