// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

/** Ionic CSS Variables **/
:root {
  --ion-font-family: 'SourceSansPro';
  --ion-color-primary: #ed001e;
  --ion-color-primary-rgb: 236,0,30;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255,255,255;
  --ion-color-primary-shade: #52000b;
  --ion-color-primary-tint: #900013;

  --ion-color-secondary: #2e2f34;
  --ion-color-secondary-rgb: 41,44,55;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255,255,255;
  --ion-color-secondary-shade: #141415;
  --ion-color-secondary-tint: #1b1b1c;

  --ion-color-tertiary: #9f111b;
  --ion-color-tertiary-rgb: 159,17,27;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255,255,255;
  --ion-color-tertiary-shade: #8c0f18;
  --ion-color-tertiary-tint: #a92932;

  --ion-color-success: #42c8b5;
  --ion-color-success-rgb: 16,220,96;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255,255,255;
  --ion-color-success-shade: #0e554a;
  --ion-color-success-tint: #032923;

  --ion-color-warning: #ffbf30;
  --ion-color-warning-rgb: 255,206,0;
  --ion-color-warning-contrast: #ffffff;
  --ion-color-warning-contrast-rgb: 255,255,255;
  --ion-color-warning-shade: #946c13;
  --ion-color-warning-tint: #483407;

  --ion-color-danger: #f04141;
  --ion-color-danger-rgb: 245,61,61;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255,255,255;
  --ion-color-danger-shade: #d33939;
  --ion-color-danger-tint: #f25454;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34,34,34;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #515258;
  --ion-color-medium-rgb: 152,154,162;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #515258;
  --ion-color-medium-tint: #1b1b1c;

  --ion-color-light: #ffffff;
  --ion-color-light-rgb: 244,244,244;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #515258;
  --ion-color-light-tint: #515258;


  --ion-background-color: #141415;
  --ion-toolbar-background: #141415;
  --ion-toolbar-color: #ffffff;
  --ion-text-color: #ffffff;
  --ion-overlay-background-color:#141415;
  --ion-background-color-rgb: rgba(15, 16, 20, 0.847);
}
