@font-face {
  font-family: SourceSansPro;
  src: url("../assets/fonts/SourceSansPro-Regular.ttf");
}
@font-face {
  font-family: SourceSansPro;
  src: url("../assets/fonts/SourceSansPro-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: SourceSansPro;
  src: url("../assets/fonts/SourceSansPro-Italic.ttf");
  font-style: italic;
}
@font-face {
  font-family: SourceSansPro;
  src: url("../assets/fonts/SourceSansPro-BoldItalic.ttf");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: SourceSansPro;
  src: url("../assets/fonts/SourceSansPro-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: SourceSansPro;
  src: url("../assets/fonts/SourceSansPro-LightItalic.ttf");
  font-style: italic;
  font-weight: 300;
}